

import {jsx, css} from '@emotion/react';
import _ from 'lodash';

const borderWidth = 2;
const style = () => css`
.fa {
  position: relative;
  color: black!important;
}

.fac-star-one-quarter {
  position: relative;

  span {
    position: relative;
    overflow: hidden;
    left: 0;
    top: 1px;

    width: calc(40% - ${2 * borderWidth * 0.4}px);
  }
}

.fac-star-three-quarter {
  position: relative;

  span {
    position: relative;
    overflow: hidden;
    left: 0;
    top: 1px;

    width: calc(80% - ${2 * borderWidth * 0.8}px);
  }
}
`

const Stars = ({ stars, size = 14, className = '', itemClass = '' }) => {
  const icons = _.range(1, 6).map(n => {
    if (stars >= n) {
      return <i key={n} aria-hidden className={`fa fa-star ${itemClass}`}></i>;
    }

    if (stars >= n - 0.3) {
      return <i key={n} aria-hidden className={`fa fac-star-three-quarter ${itemClass}`}><span><img src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1638806784/roofing/contractors/icons/star-three-quarter.svg" className="star-icon"/></span></i>;
    }

    if (stars >= n - 0.5) {
      return <i key={n} aria-hidden className={`fa fa-star-half-o ${itemClass}`}><span></span></i>;
    }

    if (stars >= n - 0.8) {
      return <i key={n} aria-hidden className={`fa fac-star-one-quarter ${itemClass}`}><span><img src="https://imagecdn.owenscorning.com/ocimages/image/upload/v1638806784/roofing/contractors/icons/star-one-quarter.svg" className="star-icon"/></span></i>;
    }

    return <i key={n} aria-hidden className={`fa fa-star-o ${itemClass}`}></i>;
  });

  return (
    <span
      role="img"
      css={style(size)}
      className={className}
      aria-label={`${stars} star${stars > 1 ? 's' : ''}`}
    >
      {icons}
    </span>
  )
};

export default Stars;
