import { useEffect, useState, useMemo } from 'react';
import styled from "@emotion/styled";
import _ from 'lodash';
import RadioButton from '../../ComponentLibrary/input-elements/oc-radio-button';
import ContentGroupDropdown from "../oc-content-group-dropdown";

const CollapsibleLink = styled.a`
  margin-top: 12px;
  display: block;
  cursor: pointer;
  font-size: 15px;
  i {
    margin-right: 7px;
  }
`;

// Styled components
const RadioButtonLabel = styled.label`
  margin-top: 12px;
  display: block;
  cursor: pointer;
  font-size: 15px;
`;

const RadioGroupHeader = styled.h4`
  color: black;
  font-size: 16px !important;
  line-height: 18px;

  @media(min-width: 1200px) {
    font-size: 18px !important;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const FlexSpan = styled.span`
  display: flex;
`;

const RadioOptionText = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const RadioButtonCount = styled.span`
  margin-left: 4px;
`;

const RadioButtonFilter = ({ options, filterCounts, defaultValue, onChange, filterAttribute, title, displayLimit, expandText, collapseText, loading, showCount = true, onComponentChange, groupOptionsBy, translationKey, t }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [modalState, setModalState] = useState({});
  options = options.filter(o => filterCounts[o.value] > 0);

  useEffect(() => {
    setModalState({});
  }, [options.map(o => o.value).join(':')]);

  if (options.length === 0) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  const limitedOptions = (!collapsed || !_.isNumber(displayLimit)) ? options : limitFn(options, displayLimit, collapsed);

  const translatedDisplayName = (option) => {
    return option.translationKey ? t(option.translationKey) : option.displayName;
  };

  const displayTitle = translationKey ? t(translationKey) : title;

  const RadioOption = (option, key) => {
    const CustomDisplayComponent = option.displayComponent;
    return (
      <RadioButtonLabel key={key}>
        <RadioOptionText>
          <RadioButton
            type="radio"
            name={filterAttribute}
            value={option.value}
            defaultChecked={defaultValue == option.value}
            onChange={() => {
              console.log("onchange");
              onChange(filterAttribute, option.value);
              onComponentChange && onComponentChange(filterAttribute, option.value);
            }}
            disabled={filterCounts[option.value] === 0 && defaultValue !== option.value}
          />
          {option.displayComponent ? <CustomDisplayComponent /> : translatedDisplayName(option)}
          {showCount ? <RadioButtonCount>({filterCounts[option.value]})</RadioButtonCount> : null}
        </RadioOptionText>
      </RadioButtonLabel>
    );
  };

  const renderRadioButtons = (limitedOptions) => {
    if (groupOptionsBy) {
      const groupedLimitedOptions = _.groupBy(limitedOptions, groupOptionsBy);
      return Object.keys(groupedLimitedOptions).map((groupOption) => (
        <div key={groupOption}>
          <RadioGroupHeader font="body">{groupOption}</RadioGroupHeader>
          {groupedLimitedOptions[groupOption].map((option, index) => (
            RadioOption(option, `${groupOption}-${index}`)
          ))}
        </div>
      ));
    } else {
      return (
        <div>
          {limitedOptions.map((option, index) => RadioOption(option, index))}
        </div>
      );
    }
  };

  return (
    <ContentGroupDropdown label={displayTitle}>
      {renderRadioButtons(limitedOptions)}
      { _.isNumber(displayLimit) && options?.length > displayLimit &&
        (collapsed
          ? (<CollapsibleLink className="expand-filters" onClick={handleClick}><i className="fa fa-plus-circle"/><span
            className="filters-text">{expandText}</span></CollapsibleLink>)
          : (<CollapsibleLink className="expand-filters" onClick={handleClick}><i className="fa fa-minus-circle"/><span
            className="filters-text">{collapseText}</span></CollapsibleLink>))
      }
    </ContentGroupDropdown>
  );
};

RadioButtonFilter.filterValueType = 'singular';
RadioButtonFilter.getChipInfo = (filter, filterValue) => {
  const selectedOption = filter.options.find(option => option.value === filterValue);
  return selectedOption ? [{ ...selectedOption }] : [];
};

export default RadioButtonFilter;
