// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("turbolinks").start()
import FindAContractorStore from "../redux/stores/FindAContractorStore";

require('@rails/activestorage').start();
require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'polyfills';
import ReactOnRails from 'react-on-rails';

import Switcher from "../components/location/Switcher";
import ContractorProfile from "../components/contractors/profile";
import FindAContractorListing from "../components/FindAContractor/Listing";
import FeatureFlagAlert from "../components/FeatureFlag/FeatureFlagAlert";
import Footer from "../components/OC/footer"
import ContractorsListing from "../components/FindAContractor/ContractorsListing";

ReactOnRails.registerStore({
  FindAContractorStore
});

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  'Location::Switcher': Switcher,
  'FeatureFlag::Featureflagalert': FeatureFlagAlert,
  'Contractors::Profile': ContractorProfile,
  'FindAContractor::Listing': FindAContractorListing,
  'OC::Footer': Footer,
  'FindAContractor::Contractorslisting': ContractorsListing,
});
