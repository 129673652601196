import {connectLocation} from '../location/LocationConnectors';
import {If} from "../Utilities";
import Image from '../OC/oc-image';

const SocialLinks = connectLocation(({contractor, t}) => (
  <If condition={contractor.facebook || contractor.twitter || contractor.linkedin || contractor.youtube || contractor.instagram || contractor.pinterest}>
    <h3 className="body-font">{t('contractor_profile.social_media')}</h3>

    <div className="filter-group">
      <div className="social-media-links">
        <If condition={contractor.facebook}>
          <a href={contractor.facebook} title="Facebook">
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/facebook.png" alt="facebook"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/facebook-alt.png" className="hover" alt="facebook"/>
          </a>
        </If>

        <If condition={contractor.twitter}>
          <a href={contractor.twitter}>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/square-x-twitter.png" alt="twitter"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/square-x-twitter-alt.png" className="hover" alt="twitter"/>
          </a>
        </If>

        <If condition={contractor.linkedin}>
          <a href={contractor.linkedin}>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/linkedin.png" alt="linked in"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/linkedin-alt.png" className="hover" alt="linked in"/>
          </a>
        </If>

        <If condition={contractor.youtube}>
          <a href={contractor.youtube}>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/youtube.png" alt="youtube"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/youtube-alt.png" className="hover" alt="youtube"/>
          </a>
        </If>

        <If condition={contractor.instagram}>
          <a href={contractor.instagram}>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/instagram.png" alt="instagram"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/instagram-alt.png" className="hover" alt="instagram"/>
          </a>
        </If>

        <If condition={contractor.pinterest}>
          <a href={contractor.pinterest}>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/icon-pinterest.svg" alt="pinterest"/>
            <Image src="https://imagecdn.owenscorning.com/ocimages/image/upload/insulation/footer/social-icons/icon-pinterest-alt.svg" className="hover" alt="pinterest"/>
          </a>
        </If>
      </div>
    </div>
  </If>
));

SocialLinks.WrappedComponent.displayName = 'SocialLinks';

export default SocialLinks;
