import {connectLocation} from '../location/LocationConnectors';
import {If} from "../Utilities";

const AboutUs = connectLocation(({contractor, t}) => (
  <If condition={contractor.aboutus}>
    <h3 className="h4 body-font" key="about">{t('contractor_profile.about_us')}</h3>

    <p dangerouslySetInnerHTML={{__html: contractor.aboutus}} />
  </If>
));

AboutUs.WrappedComponent.displayName = 'AboutUs';

export default AboutUs;
