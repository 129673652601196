import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react';
import Theme from '../../themes';
import Link from './oc-link';

const BreadcrumbContainer = styled.ul`
	list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`;

const BreadcrumbItem = styled.li`
	font-size: 12px;
  display: flex;
  margin: 0

  a {
  	color: #585858;
    text-decoration: none;

    &:hover {
      color: ${Theme.colors.brand};
    }
  }
`;

const Carrot = styled.span`
	&:before {
		content: '>';
    padding: 0 5px;
	}
`;

export const Breadcrumbs = ({ children }) => (
	<BreadcrumbContainer aria-label="Breadcrumbs">
		{children}
	</BreadcrumbContainer>
);

export const Breadcrumb = ({href, label}) => (
	<BreadcrumbItem>
		<Link href={href} data-track="lean-more" data-track-destination={label} data-track-element-location="Breadcrumbs" >{label}</Link>
		<Carrot />
	</BreadcrumbItem>
);

