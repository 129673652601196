import LocationModalOpener from '../LocationModalOpener';
import styled from '@emotion/styled';
import { connectLocation } from '../location/LocationConnectors';
import { FindAContractorConnector } from '../../redux/connectors/FindAContractorConnector';
import { If } from "../Utilities";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`

function summaryText({ city, count, invalidZip, maxDistance, region, t }) {
  if (invalidZip) return t('contractors.contractors_summary.zip_not_recognized');
  if (count === 0) return t('contractors.contractors_summary.zero_results', {maxDistance: maxDistance, city: city, region: region});

  const text = t('contractors.independent_roofing_contractors', { count, location: `${city}, ${region}` })

  // If there are multiple contractors, return the text
  if (count !== 1) return text

  // If there is one contractor, convert "Contractors" to "Contractor"
  return text.replace(new RegExp(`/${t('contractors.contractors_summary.contractors')}/i`), t('contractors.contractors_summary.contractor'))
}

function localizedDistance({ maxDistance, t }) {
  switch(parseInt(maxDistance)) {
    case 20:
      return t('contractors.max_distance.option_1')
    case 50:
      return t('contractors.max_distance.option_2')
    case 100:
      return t('contractors.max_distance.option_3')
    default:
      return maxDistance
  }
}

// city, filteredContractorsCount, invalidZip, maxDistance, region from FindAContractorConnector
// t from connectLocation
const LocationContractorsSummary = ({ bot, city, filteredContractorsCount, invalidZip, maxDistance, region, t }) => (
  <Wrapper>
    <h2 className="body-font h3 results-info">
      {summaryText({ city, count: filteredContractorsCount, invalidZip, maxDistance: localizedDistance({maxDistance, t}), region, t })}
    </h2>
    <If condition={!bot}>
      <LocationModalOpener ariaLabel="change zipcode" dataTrack="change-zip-open" text={t('contractors.change')} />
    </If>
  </Wrapper>
)

export default FindAContractorConnector(connectLocation(LocationContractorsSummary))
