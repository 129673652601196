
import { connectLocation } from "./location/LocationConnectors";
import Modal from "./Modal";
import { jsx, css } from '@emotion/react';
import loadable from '@loadable/component';

const Reusable = loadable(() => import('./OC/PageBuilder/Reusable'))

const certInfoStyles = css`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: block;
  }

  .cert-icon {
    @media (max-width: 767px) {
      text-align: center;
      display: flex;
      width: 100%;
      margin: 0 auto;
    }

    margin: 0 16px 0 0;

    img {
      max-width: 200px;
    }
  }

  .cert-icon-badges {
    margin: 0 16px 0 0;

    img {
      max-width: 35px;
      max-height: 40px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }
`;

export const ContractorBadgeModal = connectLocation(({ t, locale: { code }, details, badgeType, ...modalProps}) => {
  const badgeTypeClassName = badgeType === 'platinum' || badgeType === 'preferred' ? 'cert-icon' : 'cert-icon-badges'
  return details && details.reusable.reference ?
    (
      <Modal {...modalProps}>
        <h3 className="h4 heading">{ details?.title }</h3>
        <Reusable gutterlessChild language={ code } { ...details?.reusable } />
      </Modal>
    ) :
    (
      <Modal {...modalProps}>
        <h3 className="h4 heading">{t('contractor_profile.'+ badgeType + '_title')}</h3>

        <div css={certInfoStyles}>
          <div className={ badgeTypeClassName }>
            <img src={t('contractor_profile.'+ badgeType + '_icon')} alt={t('contractor_profile.'+ badgeType + '_title')} />
          </div>

          <p dangerouslySetInnerHTML={{__html: t('contractor_profile.'+ badgeType + '_description')}} />
        </div>
      </Modal>
    );
});
