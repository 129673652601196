
import {jsx, css} from '@emotion/react';
import { useState } from 'react';
import _ from 'lodash';
import styled from "@emotion/styled";
import {connectLocation} from "../location/LocationConnectors";
import {ContractorBadges} from "./ContractorBadges";
import {If, RequestAQuoteModal, WrappedEmailRAQQuestionModalButton} from "../Utilities";
import Modal from '../Modal';
import InfoIcon from '../OC/oc-info-icon'
import Stars from "../Stars";
import { profileURL } from './profileURL';
import useRAQQuestionModal from '../../hooks/use_raq_question_modal';

const StarRatingsLink = styled.a`
  text-decoration: inherit;
  color: #333;
  outline-color: #d40f7d;

  .star {
    color: #333;
  }

  &:hover {
    cursor: pointer;
  }
`

const Logo = styled.picture`
  display: none;

  @media (min-width: 768px) {
    display: inline-block;
    margin-right: 15px;
  }

  img {
    width: 50px;
  }
`

const contractorName = css`
  text-decoration: none;
  display: inline-block;

  &:hover {
    text-decoration: underline;

    h3 {
      color: #d40f7d;
    }
  }
`;

export const ContractorReviews = connectLocation(({ t, count, masDealerCode, stars, storeName, locale }) => {
  const [starRatingModalOpen, starRatingModalOpenChangeState] = useState(false);
  return (<div className="reviews">
    <StarRatingsLink
      data-track="view-profile"
      data-track-contractor={storeName}
      data-track-contractor-id={masDealerCode}
      href={profileURL(masDealerCode, locale?.code)}
    >
      <Stars stars={stars} size={20} className='review-stars' itemClass="star" />
      <span className="review-count" style={{ marginRight: '10px' }}>({count} {t('contractors.block.reviews')})</span>
    </StarRatingsLink>
    <InfoIcon clickHandler={() => starRatingModalOpenChangeState(true)}/>
    <Modal className="star-rating-modal" open={starRatingModalOpen} onClose={() => starRatingModalOpenChangeState(false)}>
      <h2 className="h3">{t('contractors.star_ratings_title')}</h2>
      <p>{t('contractors.star_ratings_p1')}</p>
      <p>{t('contractors.star_ratings_p2')}</p>
      <a
        href="https://pages.guildquality.com/partner/owenscorning/"
        class="cta-button"
        data-track="learn-more"
        data-track-element-location="main section CTA"
        data-track-destination="Star Ratings"
      >
        {t('contractors.learn_more_star_ratings')}
      </a>
    </Modal>
  </div>)
});

ContractorReviews.displayName = 'ContractorReviews';

export const ContractorBlock = connectLocation(({t, locale, ...props}) => {
  const {
    RAQOpen,
    RAQOnOpen,
    RAQOnClose,
    RAQFormName,
    RAQModalElementFunc
  } = useRAQQuestionModal();
  const phoneDigits = _.toString(props.phone).replace(/[^0-9A-Z]/g, '');
  const formattedPhone = phoneDigits.replace(/(.{3})(.{3})(.{4,})/, '($1) $2-$3');

  return (
  <div className="contractor">
    <RequestAQuoteModal contractor={props} open={RAQOpen} portal={RAQModalElementFunc} raqFormName={RAQFormName} onClose={RAQOnClose}/>
    <Logo>
      <source media="(min-width: 768px)" srcSet={props.logo || 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,w_65/v1589224258/roofing/contractors/house-generic.png'}/>
      <img src="" alt=""/>
    </Logo>

    <div className="name-location">
      <a
        href={profileURL(props.mas_dealer_code, locale?.code)}
        className="name"
        tabIndex={0}
        data-track="learn-more"
        data-track-element-location="main section text link"
        data-track-destination="Contractor Profile"
        data-track-professional="contractor"
      >
        <h3 className="body-font">
          {props.store_name}
        </h3>
      </a>

      <p>{props.city}, {props.state}</p>
      <div className="mobile-switch">
        <div className="contact-info">
          <p>
            <a
              href={`tel:${phoneDigits}`}
              className="phone"
              tabIndex={0}
              data-track="phone"
              data-track-professional="contractor"
              data-track-contractor-id={props.mas_dealer_code}
              data-track-contractor={props.store_name}
            >
              {formattedPhone}
            </a>
            <If condition={props.has_email}>
              <span>|</span>
              <WrappedEmailRAQQuestionModalButton contractor={props}>
                {({ onClick }) => (
                  <a
                    onClick={onClick}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        onClick();
                      }
                    }}
                    className="link-text"
                    tabIndex={0}
                    data-track="open-modal"
                    data-track-modal-name="roof-replacement-modal"
                    data-track-contractor={props.store_name}
                    data-track-contractor-id={props.mas_dealer_code}
                    data-track-form-name="email-contractor"
                  >
                    {t('contractors.email')}
                  </a>
                )}
              </WrappedEmailRAQQuestionModalButton>
            </If>
            <If condition={props.website}>
              <span>|</span>
              <a
                href={_.get(props, 'website')}
                target="_blank"
                rel="noopener"
                className="link-text"
                tabIndex={0}
                data-track="website"
                data-track-contractor={props.store_name}
                data-track-contractor-id={props.mas_dealer_code}
                data-track-professional="contractor">{t('contractors.website')}
              </a>
            </If>
          </p>
        </div>

        <ul className="professional-certs">
          <ContractorBadges {...props}/>
        </ul>
      </div>

      <If condition={props.show_request_a_quote}>
        <a className="oc-cta-button mobile-only" onClick={RAQOnOpen}
           data-track="form-start"
           data-track-form-type="lead"
           data-track-form-name="request-a-quote"
           data-track-contractor={props.store_name}
           data-track-contractor-id={props.mas_dealer_code}
           data-track-professional="contractor"
        >
          {t('contractors.request_quote')}
        </a>
      </If>
    </div>

    <div className="reviews-quote">
      <If condition={props.is_rating_available}>
        <ContractorReviews
          count={props.gq_review_count}
          masDealerCode={props.mas_dealer_code}
          stars={props.gq_review_stars}
          storeName={props.store_name}
        />
      </If>

      <If condition={props.show_request_a_quote}>
        <a
          className="oc-cta-button tablet-desktop-only"
          data-track="form-start"
          data-track-form-type="lead"
          data-track-form-name="request-a-quote"
          data-track-contractor={props.store_name}
          data-track-contractor-id={props.mas_dealer_code}
          data-track-professional="contractor"
          onClick={RAQOnOpen}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
              RAQOnOpen();
            }
          }}
          tabIndex={0}
        >
          {t('contractors.request_quote')}
        </a>
      </If>
    </div>
  </div>
)});

ContractorBlock.propTypes = {

}

ContractorBlock.WrappedComponent.displayName = 'ContractorBlock';
