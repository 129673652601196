import styled from '@emotion/styled';

const UtilityBarContainer = styled.div`
	padding: 10px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media(min-width:  768px) {
  	max-width: 768px;
  }

  @media(min-width:  1200px) {
  	max-width: 1170px;
    padding: 10px 0;
  }
`;

const UtilityBar = ({ children }) => (
	<UtilityBarContainer>
		{ children }
	</UtilityBarContainer>
);

export default UtilityBar;