import { wrapRef } from '../../data';

const useContractorLevels = ({ t }) => {
  const contractorLevels = [
    {
      key: 'platinum_preferred',
      value: t('contractors.platinum_title_short'),
      modal: {
        title: t('contractor_profile.platinum_title'),
        reusable: {
          reference: wrapRef('Cms::Content', {
            type: 'Reusable',
            path: 'roofing/contractors',
            name: 'Platinum Tier Modal',
          }),
        },
      },
    },
    {
      key: 'preferred',
      value: t('contractors.preferred_title_short'),
      modal: {
        title: t('contractor_profile.preferred_title'),
        reusable: {
          reference: wrapRef('Cms::Content', {
            type: 'Reusable',
            path: 'roofing/contractors',
            name: 'Preferred Tier Modal',
          }),
        },
      },
    },
  ];

  return { contractorLevels };
};

export default useContractorLevels;
