import { applyMiddleware, combineReducers, createStore } from 'redux';
import { findAContractorReducer } from '../connectors/FindAContractorConnector';
import { composeWithDevTools } from 'redux-devtools-extension';
import { featureFlagReducer } from '../../components/FeatureFlag/FeatureFlagConnectors';
import thunk from "redux-thunk";

export default function FindAContractorStore(props) {
  const initialState = props;

  const noOpReducer = (initial) => (state = initial || null) => state;

  const reducer = combineReducers({
    location: noOpReducer(initialState.location),
    featureFlag: featureFlagReducer,
    findAContractor: (state = initialState.findAContractor, action) => findAContractorReducer(state, action)
  });

  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools({name: 'findAContractorStore'})(
      applyMiddleware(thunk)
    )
  );

  store.dispatch({type: 'FindAContractor/init'});

  return store;
}
