import styled from "@emotion/styled";

// Components
import Map from "../../ComponentLibrary/oc-map";

// Styled Components
const StickyMap = styled(Map)`
  position: sticky;
  top: 0;
`;

const DistributorMap = ({ locations }) => {
  return (
    <StickyMap locations={locations} />
  )
}

export default DistributorMap;
