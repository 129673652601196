import { Fragment, useEffect, useState } from "react";
import { connectLocation } from "../location/LocationConnectors";
import _ from 'lodash';
import cms_api from '../../cms_api';

export const getBadgeData = _.memoize(
  async (code) => {
    const [response, response_en] = await Promise.all([cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: code,
        route: '/roofing/contractors',
        name: 'FAC Badges',
      },
      fields: {
        '*': 'contents',
      },
    }), cms_api.get_single_contents({
      filter: {
        type: 'Cms::Content::Attribute',
        language_iso_code: 'en-US',
        route: '/roofing/contractors',
        name: 'FAC Badges',
      },
      fields: {
        '*': 'contents',
      },
    })]);
    return response?.items.map(item => ({
      ...item,
      analyticsName: response_en?.items?.filter(item2 => item2.value == item.value)?.[0]?.label || item.label
    }));
  }
);

function ContractorBadge({openModal, modalKey, imgUrl, altText, dataTrackDestination}) {
  return <li>
        <a
          onClick={() => openModal(modalKey)}
          tabIndex="0"
          data-track="tooltip"
          data-track-destination={dataTrackDestination}
          onKeyPress={(e) => { if (e.key === 'Enter') openModal(modalKey) }}>
          <img src={imgUrl} alt={altText}/>
        </a>
    </li>
}

export const ContractorBadges = connectLocation(({t, locale: { code }, active_certifications, ...props}) => {
  const [badgeData, setBadgeData] = useState([])
  useEffect(() => {
    getBadgeData(code).then(data => setBadgeData(data))
  }, [])
  const activeBadges = active_certifications || []

  return (
    <Fragment>
      {
        badgeData.map(badge => {
          if (activeBadges.filter(value => (Array.wrap(badge.value || [])).includes(value)).length > 0) {
            return <ContractorBadge
                      modalKey={ badge.value }
                      imgUrl={ badge.icon?.file }
                      altText={ badge.icon?.alt }
                      dataTrackDestination={ badge.analyticsName }
                      openModal={props.openModal}/>
          } else {
            return null;
          }
        })
      }
    </Fragment>
  )
});
