import { connectLocation } from "../location/LocationConnectors";
import { If } from "../Utilities";
import styled from "@emotion/styled";
import _ from 'lodash';

// Imported Components
import { H3 } from '../ComponentLibrary/text-elements/oc-h';
import ResultBlock from '../ComponentLibrary/filter-result-blocks/oc-result-block';
import IconMapMarker from '../ComponentLibrary/icons/icon-map-marker';
import Button from '../ComponentLibrary/oc-button'
import { useState } from "react";
import { EmailContractorModal, RequestAQuoteModal } from "../Utilities";
import { ContractorReviews } from "./ContractorBlock";
import { ContractorBadgeModal } from "../CertificationsModal";
import { profileURL } from "./profileURL";

// Styled Components
const Name = styled(H3)`
  display: inline-block;
  padding: 0 8px 0 0;
  margin-bottom: 8px;
  text-transform: capitalize;
`;

const ItemNumber = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 8px;
`;

const Distance = styled.div`
  font-size: 14px;
  font-style: italic;
  display: inline-block;
  line-height: 18px;
  padding-bottom: 2px;
  color: #333;
`;

const Address = styled.div`
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
`;

const Badges = styled.div`
  line-height: 18px;
  display: block;
  margin-bottom: 8px;
  margin-top: 8px;
  text-transform: capitalize;

  img {
    margin-right: 16px;
    margin-bottom: -3px;
    width: 20px;
  }
`;

const MobileSwitch = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media(min-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.span`
  margin: 0 8px;

  @media(min-width: 1200px) {
    margin: 0 16px;
  }
`;

const ActionLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: 8px;
    margin-bottom: -3px;
  }
`;

const Profile = styled.a`
  text-decoration: none;
`

const Notes = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: #333;
  text-transform: capitalize;
`;

const MainCta = styled.div`
  padding-top: 10px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: self-end;

  @media(min-width: 768px) {
    padding: 0;
  }
  a{
    white-space: nowrap;
  }
`;

const CtaGroup = styled.div`
  //width: calc(100% - 50px);
  display: flex;
  a {
    margin-right: 5px;
  }
`

const typeFormatter = (type) => type?.replace('_', '-').trim()
const telFormatter = (phone) => `tel:${phone?.replaceAll(/\(|\s/g,'').replaceAll(/\)/g,'-')}`
const distanceFormatter = (distance, t) => t('generic_locator.miles_away', {distance: Math.round(distance)})
const telDisplayFormatter = (phone) => {
  let phoneNumber = phone ? phone?.match(/\d+/g).join('') : ""
  let sectioned = phoneNumber?.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (sectioned) {
    return '(' + sectioned[1] + ') ' + sectioned[2] + '-' + sectioned[3];
  } else {
    return phoneNumber
  }
}

export const ContractorResultBlock = connectLocation(({
  t,
  resource,
  suppressMapView,
  hideCallCTA,
  websiteCTALabel,
  emailCTALabel,
  directionsCTALabel,
  mainCTALabel,
  locale,
}) => {
  const website = resource.website_address || resource.website
  const fullAddress = `${resource.address}, ${resource.city}, ${resource.state} ${resource.zip || resource.postal}`;
  const [raqOpen, raqChangeState] = useState(false);
  const [emailOpen, emailChangeState] = useState(false);
  const raqModalElementFunc = () => document.getElementById('raq-modal');
  const emailModalElementFunc = () => document.getElementById('email-modal');

  return (
    <ResultBlock media={resource.logo} alt={resource.name}>
      <RequestAQuoteModal contractor={resource} open={raqOpen} portal={raqModalElementFunc} onClose={() => raqChangeState(false)}/>
      <EmailContractorModal contractor={resource} open={emailOpen} portal={emailModalElementFunc} onClose={() => emailChangeState(false)}/>
      <div>
        <Name font="body" className="name">
          {resource.list_number && !suppressMapView ? <ItemNumber data-testid="item-number">{resource.list_number}.</ItemNumber> : null}
          <Profile data-track="view-profile"
             data-track-contractor={resource.name}
             data-track-contractor-id={resource.dealer_code}
             href={profileURL(resource.dealer_code, locale?.code)}
          >
            <span dangerouslySetInnerHTML={{__html: resource.name}} />
          </Profile>
        </Name>
          {resource.distance && !Number.isNaN(resource.distance) ? (
            <Distance>{distanceFormatter(resource.distance, t)}</Distance>
          ) : null}

        <Address>{fullAddress}</Address>

        <MobileSwitch>
          <div>
            <If condition={fullAddress}>
              <ActionLink
                href={`http://maps.google.com/?q=${fullAddress}`}
                tabIndex={0}
                data-track="view-directions"
                data-track-buy-type={typeFormatter(resource.typeDisplayName)}
                data-track-supplier-name={resource.name}
              >
                <IconMapMarker width="13" fill="currentColor" className="icon" />{directionsCTALabel ? directionsCTALabel : t('generic_locator.directions')}
              </ActionLink>
            </If>
            <If condition={fullAddress && (resource.phone || resource.email || website)}>
              <Divider>|</Divider>
            </If>
            <If condition={resource.phone}>
              <ActionLink
                href={telFormatter(resource.phone)}
                tabIndex={0}
                data-track="phone"
                data-track-buy-type={typeFormatter(resource.typeDisplayName)}
                data-track-supplier-name={resource.name}
              >
                {telDisplayFormatter(resource.phone)}
              </ActionLink>
            </If>
            <If condition={resource.phone && resource.hasEmail}>
              <Divider>|</Divider>
            </If>
            <If condition={resource.hasEmail}>
              <ActionLink
                onClick={() => emailChangeState(true)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    emailChangeState(true)
                  }
                }}
                tabIndex={0}
                data-track="form-start"
                data-track-contractor={resource.store_name}
                data-track-contractor-id={resource.mas_dealer_code}
                data-track-form-name="email-contractor"
              >
                {t('contractors.email')}
              </ActionLink>
            </If>
            <If condition={website && (resource.email || resource.phone)}>
              <Divider>|</Divider>
            </If>
            <If condition={website}>
              <ActionLink
                href={website}
                target="_blank"
                rel="noopener"
                tabIndex={0}
                data-track="website"
                data-track-buy-type={typeFormatter(resource.typeDisplayName)}
                data-track-supplier-name={resource.name}
              >
                {websiteCTALabel ? websiteCTALabel : t('generic_locator.website')}
              </ActionLink>
            </If>
            { resource.product_stock_display_text && <Notes dangerouslySetInnerHTML={{ __html: resource.product_stock_display_text }} /> }
          </div>
        </MobileSwitch>
        {resource.badges.length ?
          <Badges>
            {
              (resource.badges.map(badge => {
                  return (
                    <ActionLink
                      onClick={() => resource.setSelectedBadge(badge.value)}
                      tabIndex="0"
                      data-track="tooltip"
                      data-track-destination={badge.analyticsName}
                    >
                      <img src={badge.icon.file} alt={badge.icon.alt}/>
                    </ActionLink>
                  )})
              )
            }
          </Badges>
          : null
        }
      </div>

      <If condition={resource.is_rating_available || resource.phone || resource.show_request_quote}>
        <MainCta>
          <If condition={resource.is_rating_available}>
            <ContractorReviews
              count={resource.review_count}
              masDealerCode={resource.dealer_code}
              stars={resource.review_stars}
              storeName={resource.name}
            />
          </If>
          <CtaGroup>
            <If condition={resource.phone && !hideCallCTA}>
              <Button
                href={telFormatter(resource.phone)}
                data-track="phone"
                data-track-buy-type={typeFormatter(resource.typeDisplayName)}
                data-track-supplier-name={resource.name}
              >
                {mainCTALabel ? mainCTALabel : t('generic_locator.call_now')}
              </Button>
            </If>
            <If condition={resource.show_request_quote}>
              <a className="oc-cta-button" onClick={() => raqChangeState(true)}
                 data-track="form-start"
                 data-track-form-type="lead"
                 data-track-form-name="request-a-quote"
                 data-track-contractor={resource.name}
                 data-track-contractor-id={resource.dealer_code}
                 data-track-professional="contractor"
              >
                {t('contractors.request_quote')}
              </a>
            </If>
          </CtaGroup>
        </MainCta>
      </If>
    </ResultBlock>
  )});
