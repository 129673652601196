import { React, Component } from 'react';
import styled from '@emotion/styled';

// Components
import LocationModalOpener from '../../ComponentLibrary/oc-location-modal-opener';
import TabList from '../../ComponentLibrary/oc-tabs';
import Tab from '../../ComponentLibrary/oc-tab';
import { H2, H3 } from '../../ComponentLibrary/text-elements/oc-h';
import IconMap from '../../ComponentLibrary/icons/icon-map';
import {connectLocation} from "../../location/LocationConnectors";

// Styled Components
const HeadingContainer = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: auto;
  }

  // Temp solution for mobile styling tabs
  + .tabs-list {
    @media screen and (max-width: 600px) {
      margin-top: 0.5em
    }
  }
`;

const WrappedH3 = styled(H3)`
  margin-bottom: 0;
  margin-right: 0.5em;
`;

// Temp solution for mobile styling tabs; may need to revist after MVP
// when tabbed views (+ map) are ready to go live.
const WrappedTabs = styled(TabList)`
  margin-bottom: 0;
`;

const ContentHeader = connectLocation(({ t, ...props })  => {
  const { valid, count, city, region, primaryActive, activatePrimary, secondaryActive, activateSecondary, suppressMapView } = props;

  return (
    <>
      <HeadingContainer>
        { valid && <WrappedH3 tag="h2" font="body"><span>{count}</span> {t('generic_locator.locations_near')} <span>{city}, {region}</span></WrappedH3> }
        { !valid && <WrappedH3 tag="h2" font="body">{t('generic_locator.invalid_zip')}</WrappedH3> }
        <LocationModalOpener ariaLabel="change zipcode" dataTrack="change-zip-open" dataTrackModalName="Region Selector" />
      </HeadingContainer>

      <WrappedTabs className="tabs-list">
        {!suppressMapView ? (
          <>
            <Tab label={t('generic_locator.map_view')} active={secondaryActive} onClick={() => activateSecondary()} iconImage={<IconMap width="13px" fill="currentColor" />} />
            <Tab label={t('generic_locator.list_view')} active={primaryActive} onClick={() => activatePrimary()} icon="List" />
          </>
        ) : null}
      </WrappedTabs>
    </>
  )
})

export default ContentHeader
