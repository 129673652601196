import * as React from 'react';
import _ from "lodash";
import { ContractorBlock } from "./ContractorBlock";
import { FindAContractorConnector } from "../../redux/connectors/FindAContractorConnector";
import { connectLocation } from "../location/LocationConnectors";
import { PlatinumContractorLevel, PreferredContractorLevel } from "./ContractorLevels";

const SkipToFooter = connectLocation(({ t }) => {
  return (
    <a href="#oc-footer" className="skip-to-footer">{ t('contractors.scroll_to_footer') }</a>
  )
});

const Contractors = ({ contractors, openModal }) => (
  <>
    {
      contractors.map((contractor, index) => {
        const interval = 10
        let everyAfterInterval = !((index + 1) % interval)
        return (
          <React.Fragment key={index}>
            <ContractorBlock key={contractor.mas_dealer_code} {...contractor} openModal={openModal} />
            {everyAfterInterval && <SkipToFooter />}
          </React.Fragment>
        )
      })
    }
  </>
)

const PlatinumContractors = ({ contractors, filters, openModal }) => {
  if (_.isEmpty(contractors)) return null

  const show = filters.contractor_level.length === 0 || filters.contractor_level.includes('platinum_preferred')
  if (!show) return null

  return (
    <div className="contractor-level-wrapper">
      <PlatinumContractorLevel />
      <Contractors contractors={contractors} openModal={openModal} />
    </div>
  )
}

const OtherContractors = ({ contractors, filters, openModal }) => {
  if (_.isEmpty(contractors)) return null

  const show = filters.contractor_level.length === 0 || filters.contractor_level.includes('preferred')
  if (!show) return null

  return (
    <div className="contractor-level-wrapper">
      <PreferredContractorLevel />
      <Contractors contractors={contractors} openModal={openModal} />
    </div>
  )
}

// filteredContractors, filters from FindAContractorConnector
const ContractorList = ({ filteredContractors, filters, openModal }) => {
  const { platinum = [], other = [] } = _.groupBy(filteredContractors, function(contractor) {
    return contractor.is_preferred_platinum ? "platinum" : "other"
  })

  return (
    <div aria-live="polite" id="contractor-list">
      <PlatinumContractors contractors={platinum} filters={filters} openModal={openModal} />
      <OtherContractors contractors={other} filters={filters} openModal={openModal} />
    </div>
  )
}

export default FindAContractorConnector(ContractorList)
