import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import ContractorList from './ContractorList';
import { FACFilterChips } from './FilterChips';
import LocationContractorsSummary from './LocationContractorsSummary';
import MobileFiltersOpener from './MobileFiltersOpener';
import SortBySelector from './SortBySelector';
import { FindAContractorConnector } from '../../redux/connectors/FindAContractorConnector';
import { connectLocation } from '../location/LocationConnectors';

const SkipToResults = connectLocation(({ t }) => (
  <a className="mobile-tablet-only skip-to-results" href="#contractor-list">
    {t('contractors.skip_to_results')}
  </a>
));

const Results = ({
  bot,
  openModal,
  mobileFiltersOpened,
  setMobileFiltersOpened,
  badgeData,
}) => (
  <div className="oc-contractor-list-container">
    <LocationContractorsSummary bot={bot} />
    <FACFilterChips badgeData={badgeData} />
    <SortBySelector />
    <SkipToResults />
    <MobileFiltersOpener
      mobileFiltersOpened={mobileFiltersOpened}
      setMobileFiltersOpened={setMobileFiltersOpened}
    />
    <ContractorList openModal={openModal} />
  </div>
);

const ConnectedResults = FindAContractorConnector(Results);

ConnectedResults.WrappedComponent.displayName = 'Results';

export default props => (
  <Provider store={ReactOnRails.getStore('FindAContractorStore')}>
    <ConnectedResults {...props} />
  </Provider>
);
