import {connectLocation} from '../location/LocationConnectors';
import {If} from "../Utilities";

const DealerVideos = connectLocation(({contractor, t}) => (
  <If condition={contractor.DealerVideos}>
    <h3 className="h4 body-font" key="videos">{t('contractor_profile.videos')}</h3>

    <div className="filter-group">
      <div className="oc-gallery list">
        <div className="resource">
            <iframe width="100%" class="embed-responsive-item" src={contractor.DealerVideos}></iframe>
          <p></p>
        </div>
      </div>
    </div>
  </If>
));

DealerVideos.WrappedComponent.displayName = 'DealerVideos';

export default DealerVideos;
