import _ from 'lodash';

export const filters = {
  contractor_level: {
    analytics: {
      name: 'Contractor Level',
      option: name => `${_.startCase(name)} Contractor`,
    },
    tabIndex: 0
  },
  certifications: {
    analytics: {
      name: 'Certifications',
      option: name => _.startCase(name),
    },
    tabIndex: 0
  },
  star_rating: {
    analytics: {
      name: 'Average Rating',
      option: name => (name === 0) ? 'All ratings' : (`${name} Star${name > 1 ? 's' : ''}`),
    },
    tabIndex: 0
  }
};

export const filterContractors = (contractors, filters) => {
  const { contractor_level = [], certifications = [], star_rating = [] } = filters;

  return (
    contractors.filter(contractor => (
      (
        _.isEmpty(contractor_level)
        || (_.includes(contractor_level, "platinum_preferred") && contractor.is_preferred_platinum)
        || (_.includes(contractor_level, "preferred") && !contractor.is_preferred_platinum)
      )
      && _.every(certifications, (cert) => {
        const activeBadges = contractor.active_certifications||[]
        return activeBadges.filter(value => (Array.wrap(cert || [])).includes(value)).length > 0
      })
      && _.every(star_rating, (rating) => contractor.gq_review_stars >= rating)
    ))
  );
};
