import { useState } from 'react';
import { connectLocation } from "../location/LocationConnectors";
import Image from "../OC/oc-image";

export const PlatinumContractorLevel = connectLocation(({ t }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <div className="contractor-levels" aria-label="learn more about platinum contractors">
        <input
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          type="checkbox"
          id="platinum"
        />
        <label
          tabIndex={0}
          htmlFor="platinum"
          data-track={isChecked ? "conceal" : "reveal"}
          aria-checked={isChecked}
          data-track-accordion="platinum"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
              setIsChecked(!isChecked)
            }
          }}
        >
          <Image src={t('contractors.platinum_icon')} alt={t('contractors.platinum_badge')} />
          {t('contractors.learn_more')}
        </label>
        <div className="content" aria-hidden={!isChecked}>
          <p>{t('contractors.platinum_description')}</p>
        </div>
      </div>
    </>
  )
})

PlatinumContractorLevel.WrappedComponent.displayName = 'PlatinumContractorLevel';

export const PreferredContractorLevel = connectLocation(({ t }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <>
      <div aria-expanded={isChecked} className="contractor-levels" aria-label="learn more about preferred contractors">
        <input
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          type="checkbox"
          id="preferred"
        />
        <label
          tabIndex={0}
          htmlFor="preferred"
          data-track="reveal"
          aria-checked={isChecked}
          data-track-accordion="preferred"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
              setIsChecked(!isChecked)
            }
          }}
        >
          <Image src={t('contractors.preferred_icon')} alt={t('contractors.preferred_badge')} />
          {t('contractors.learn_more')}
        </label>
        <div className="content" aria-hidden={!isChecked}>
          <p>{t('contractors.preferred_description')}</p>
        </div>
      </div>
    </>
  )
})

PreferredContractorLevel.WrappedComponent.displayName = 'PreferredContractorLevel';
