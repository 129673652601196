import { Global, css } from '@emotion/react';
import React from 'react';

import { connectLocation } from '../location/LocationConnectors';

const HideWebChat = () => {
  const styles = css`
    .cx-widget .cx-webchat {
      z-index: 99;
    }
  `;

  return <Global styles={styles} />;
};

const bodyScroll = css`
  body {
    position: fixed;
    left: 0;
    right: 0;
  }
`;

const MobileFiltersOpener = connectLocation(
  ({ t, mobileFiltersOpened, setMobileFiltersOpened }) => {
    const onFilterOpen = () => {
      setMobileFiltersOpened(true);
    };

    return (
      <div className="mobile-filters">
        <a href="#mobile-filters" className="oc-icon" onClick={onFilterOpen}>
          <i className="icon filters" />
          {t('contractors.filters_credentials_heading')}
        </a>
        {mobileFiltersOpened && <HideWebChat />}
        {mobileFiltersOpened && <Global styles={bodyScroll} />}
      </div>
    );
  }
);

export default MobileFiltersOpener;
