import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { connectLocation } from "../location/LocationConnectors";
import LocationSwitcher from "../location/Switcher";
import wrappedInReduxProvider from "../wrappedInReduxProvider";

// Components
import Hero from "../OC/oc-hero";
import UtilityBar from "../ComponentLibrary/layout/oc-utility-bar";
import { Breadcrumb, Breadcrumbs } from "../ComponentLibrary/oc-breadcrumbs";

import Map from "../Insulation/Residential/Map";

import Container from "../OC/oc-container";
import ContentHeader from "../Insulation/Residential/ContentHeader";
import FilterListResults from "../ComponentLibrary/filter-list/oc-filter-list-results";
import CheckboxSelector from "../ComponentLibrary/filter-list/oc-filters";
import DistanceSelector from "../ComponentLibrary/oc-distance-selector";
import { BrowserRouter as Router } from "react-router-dom";
import _ from "lodash";
import { SortDropDown } from "../SDS/SortDropDown";
import { getBadgeData } from "./ContractorBadges";
import { ContractorResultBlock } from "./ContractorResultBlock";
import ResultHeaderComponent from "../OC/PageBuilder/Locator/resultContentHeader";
import Stars from "../Stars";
import RadioButtonFilter from "../ComponentLibrary/filter-tree/oc-radio-filter";
import { ContractorBadgeModal } from "../CertificationsModal";

const ResultComponent = ({...props}) => {
  return(
    <>
      <ContractorResultBlock resource={props} suppressMapView hideCallCTA />
    </>
  )}

const Disclaimer = connectLocation(({ t }) => {
  return (
      <p className="footnote" dangerouslySetInnerHTML={{__html: t('contractors.disclaimer')}}></p>
  );
});

const webSiteFormatter = (website) => {
  const webSitePattern = new RegExp( /(http(s?)):\/\//);
  return webSitePattern.test(website)
    ? website
    : '//' + website
}


const getAddress = (address1, address2) => {
  var fullAddress = "";
  address1 = address1?.trim();
  address2 = address2?.trim();

  if (address1) {
    fullAddress += address1;
  }

  if (address2) {
    if (fullAddress) {
      fullAddress += ", " + address2;
    } else {
      fullAddress = address2;
    }
  }
  return fullAddress;
}

const sortStyle = css`
  margin-bottom: 24px;
`;

const ContractorsListing = connectLocation(({ t, setLocationModalForceReload, ...props })  => {
  const [badgeData, setBadgeData] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState([]);
  const [lists, setLists] = useState([]);
  const [badgeModalProps, setBadgeModalProps] = useState({});

  useEffect(() => {
    // Fetch badge data when the component mounts
    getBadgeData(props.userLocale.code)
      .then((badgeData) => {
        setBadgeData(badgeData);
      })
      .catch((error) => {
        console.error("Error fetching badge data:", error);
      });
  }, [props.userLocale.code]);


  const headerData = {
    platinum_contractor_level: {
      badge: t('contractors.platinum_icon'),
      alt: t('contractors.platinum_badge'),
      ctaLabel: t('contractors.learn_more'),
      text: t('contractors.platinum_description')
    },
    preferred_contractor_level: {
      badge: t('contractors.preferred_icon'),
      alt: t('contractors.preferred_badge'),
      ctaLabel: t('contractors.learn_more'),
      text: t('contractors.preferred_description')
    },
  }

  const badgeOptions = () => {
    try {
      return (badgeData || []).map(cert => ({
        value: cert.value,
        displayName: cert.label,
        icon: cert.icon,
        showInfo: true,
        infoIconProps: {onClick: () => setSelectedBadge( Array.wrap(cert.value || []))},
      }));
    } catch (error) {
      console.error('Error fetching badge data:', error);
      return [];
    }
  };

  const parseData = (result) => {
    return result.map(row => {
      let activeBadges = badgeData.map(badge => {
        if (row.active_certifications.filter(value => (Array.wrap(badge.value || [])).includes(value)).length > 0) {
          return badge
        }
      });

      return {
        logo: row.logo || 'https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,w_65/v1589224258/roofing/contractors/house-generic.png',
        address: getAddress(row.address_line1, row.address_line2),
        city: row.city,
        country: row.country || 'USA',
        distance: row.distance_in_miles,
        latitude: parseFloat(row.latitude),
        longitude: parseFloat(row.longitude),
        phone: row.phone,
        hasEmail: row.has_email,
        state: row.state,
        name: row.store_name,
        dealer_code: row.mas_dealer_code,
        review_count: row.gq_review_count,
        avg_rating: Math.floor(+row.gq_review_stars),
        review_stars: row.gq_review_stars,
        show_request_quote: row.show_request_a_quote,
        is_rating_available: row.is_rating_available,
        website_address: row.website ? webSiteFormatter(row.website) : null,
        zip: row.mas_dealer_zip,
        contractor_level: row.is_preferred_platinum ? 'platinum_preferred' : 'preferred',
        is_green_pledge_certified: row.is_green_pledge_certified,
        is_platinum_pride_award: row.is_platinum_pride_award,
        is_preferred_platinum: row.is_preferred_platinum,
        is_profile_available: row.is_profile_available,
        is_review_map_available: row.is_review_map_available,
        is_reviews_available: row.is_reviews_available,
        is_surenail_certified: row.is_surenail_certified,
        certifications: row.active_certifications,
        contractor: row,
        badges: activeBadges.filter(badge => badge),
        setSelectedBadge: setSelectedBadge,
      }
    })
  };

  const mapListData = (data) => {
    const { platinum = [], other = [] } = _.groupBy(data, function(contractor) {
      return contractor.is_preferred_platinum ? "platinum" : "other"
    })
    return [
      {
        name: 'platinum_contractor_level',
        headerData: headerData.platinum_contractor_level,
        data: parseData(platinum)
      },
      { name: 'preferred_contractor_level', headerData: headerData.preferred_contractor_level, data: parseData(other) },
    ];
  }

  const updateDistance = (radius) => {
    if (props.address.valid) {
      fetch(MDMS_URL + `/api/v2/roofing/contractors?radius=${radius}&zip=${props.address.zip_code}&auto_expand=1&state=${props.address.region}&city=${props.address.city}&c_code=${props.userLocale.code}`)
        .then(response => response.json(), err => {})
        .then(json => setLists(mapListData(json?.contractors)))
    }
  }

  const sorts= {
    fields: {
      default: {
        attribute: '',
        displayName: t('contractors.sort_by.default'),
        direction: 'asc',
      },
      name_asc: {
        attribute: 'name',
        displayName: t('contractors.sort_by.alphabetical'),
        direction: 'asc',
      },
      name_desc: {
        attribute: 'name',
        displayName: t('contractors.sort_by.alphabetical_reverse'),
        direction: 'desc',
      },
      distance: {
        attribute: 'distance',
        displayName: t('contractors.sort_by.distance'),
        direction: 'asc',
      },
      review_count: {
        attribute: 'review_count',
        displayName: t('contractors.sort_by.review_count'),
        direction: 'asc',
      },
      review_stars: {
        attribute: 'review_count',
        displayName: t('contractors.sort_by.star_rating'),
        direction: 'asc',
      },
    },
    component: SortDropDown,
    props: {label: t('contractors.sort_by.sort_by'), styleProp: sortStyle},
  };

  const contractorLevels = [
    { value: 'platinum_preferred',
      displayName: t('contractors.platinum_title_short'),
      showInfo: true,
      infoIconProps: {onClick: () => setBadgeModalProps({badgeType: 'platinum'})},
    },
    { value: 'preferred',
      displayName: t('contractors.preferred_title_short'),
      showInfo: true,
      infoIconProps: {onClick: () => setBadgeModalProps({badgeType: 'preferred'})},
    },
  ];

  const ratingOptions = () => {
    return [4, 3, 2, 1, 'all'].map(rating => {
      return {
        value: rating,
        displayComponent: rating > 0 ?
          (() => (
            <>
              <Stars stars={rating} />
              <span aria-hidden>&nbsp;{t('contractors.and_up_html')}</span>
            </>
          ))
          : (() => t('contractors.all_ratings')),
        displayName: rating === 0 ? t('contractors.all_ratings') : `${rating} ${t(`contractors.star${rating > 1 ? 's' : ''}`)} ${t('contractors.and_up_html')}`,
      }
    });
  }

  const modalOnClose = () => {
    setSelectedBadge([]);
    setBadgeModalProps({});
  }

  const filters= {
    distance: {
      component: DistanceSelector,
      displayName: t('contractors.distance'),
      options: [
        { displayName: '20', value: '20' },
        { displayName: '40', value: '40' },
        { displayName: '60', value: '60' }
      ],
      valueType: 'singular',
      onChange: updateDistance,
      suppressCount: true,
      matcher: (item, filterValue, attribute, itemValue) => (Number(itemValue) <= Number(filterValue))
    },
    contractor_level: {
      component: CheckboxSelector,
      displayName: t('contractors.contractor_levels'),
      options: contractorLevels,
      valueType: 'multiple',
    },
    certifications: {
      component: CheckboxSelector,
      displayName: t('contractors.certifications'),
      options: badgeOptions(),
      valueType: 'multiple',
    },
    avg_rating: {
      component: RadioButtonFilter,
      displayName: t('contractors.star_rating'),
      options: ratingOptions(),
      valueType: 'singular',
      matcher: (item, filterValue, attribute, itemValue) => (filterValue === 'all' || (itemValue >= filterValue)),
    },
    noOperation: {
      component: Disclaimer,
      options: [],
    },
  }

  useEffect(() => {
    if(badgeData.length) {
      setLists(mapListData(props.result));
    }
  }, [badgeData, props.result]);

  useEffect(() => {
    if(badgeData.length && selectedBadge.length) {
      setBadgeModalProps({
        onClose: modalOnClose,
        component: ContractorBadgeModal,
        resource:  badgeData.filter(badge => (Array.wrap(badge.value || []).filter(val => selectedBadge.includes(val))).length > 0)[0]
      })
    }
  }, [badgeData, selectedBadge]);

  useEffect(() => {
    function removeFiltersOnLocationChange() {
      setLocationModalForceReload({
        locationChangeShouldForceReload: true,
        filtersToReset: Object.keys(filters).map((key) => filters[key].valueType === 'multiple' ? `${key}[]` : key),
      });
    }

    removeFiltersOnLocationChange();

    return () => {
      setLocationModalForceReload({
        filtersToReset: [],
      });
    }
  }, [])

  const contentHeaderProps = {
    valid: props.address.valid,
    city: props.address.city,
    region: props.address.region,
    suppressMapView: true
  }

  return (
    <>
      { badgeModalProps &&
        (badgeModalProps.badgeType || badgeModalProps.resource) &&
        <ContractorBadgeModal open details={ badgeModalProps?.resource?.details } onClose={modalOnClose} badgeType={badgeModalProps.badgeType} locale={props.userLocale?.code} />
      }
      <UtilityBar>
        <Breadcrumbs>
          <Breadcrumb href="/roofing" label={t('contractors.breadcrumb_roofing')} />
        </Breadcrumbs>
        <LocationSwitcher forceReload={true} showLocale={true}/>
      </UtilityBar>

      <div css={css`
        & > div {
          @media (max-width: 1199px) {
            background-size: cover;
          }


        }`}>

        <Hero
          imgDesktop="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,w_2680,q_30/v1562789212/roofing/contractors-hero.png"
          imgTablet="https://imagecdn.owenscorning.com/ocimages/image/upload/f_auto,w_1340,q_60/v1562789212/roofing/contractors-hero.png"
          prehead=""
          h1={t('contractors.hero_heading')}
          posthead={t('contractors.hero_posthead')}
        />
      </div>

      <Container maxWidth="1170">
        <div css={css`
          @media (min-width: 768px) {
              .group-info-container {
                .info-badge {
                  width: auto;
                  max-height: 40px;
                }
              }
            }
        `}
        >
          <FilterListResults
            suppressMapView
            contentHeader={{ component: ContentHeader, props: contentHeaderProps }}
            defaultDistance={20}
            filters={filters}
            lists={lists}
            sorts={sorts}
            primaryActiveDefault={true}
            resetDistance={updateDistance}
            resultComponent={ResultComponent}
            resultHeaderComponent={ResultHeaderComponent}
            secondaryContent={Map}
            locale={props.userLocale}
          />
        </div>
      </Container>
    </>
  )
});

export default wrappedInReduxProvider((props) => (
  <Router>
    <ContractorsListing {...props}/>
  </Router>
));
