import styled from '@emotion/styled';
import { FindAContractorConnector } from '../../redux/connectors/FindAContractorConnector';
import { connectLocation } from '../location/LocationConnectors';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  .oc-form {
    .select-container {
      align-items: center;
      display: flex;

      label {
        color: #333;
        font-weight: normal;
        margin-right: .5em;
      }

      select {
        outline: none;
      }
      select:focus {
        outline-offset: 2px;
        outline-color: #d40f7d;
        outline-style: solid;
        outline-width: 2px;
      }
    }
  }
`

// setSortBy from FindAContractorConnector
const SortBySelector = connectLocation(({ t, setSortBy, filteredContractorsCount }) => {
  if (filteredContractorsCount === 0) return null

  return (
    <Wrapper>
      <form className="oc-form" autoComplete="off">
        <div className="select-container">
          <label htmlFor="sort-by">{t('contractors.sort_by.sort_by')}:</label>
          <select tabIndex="0" id="sort-by" onChange={(evt) => setSortBy(evt.target.value)}>
            <option value="default">{t('contractors.sort_by.default')}</option>
            <option value="a-z">{t('contractors.sort_by.alphabetical')}</option>
            <option value="z-a">{t('contractors.sort_by.alphabetical_reverse')}</option>
            <option value="distance">{t('contractors.sort_by.distance')}</option>
            <option value="reviews">{t('contractors.sort_by.review_count')}</option>
            <option value="rating">{t('contractors.sort_by.star_rating')}</option>
          </select>
        </div>
      </form>
    </Wrapper>
  )
});

export default FindAContractorConnector(SortBySelector)
